<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-25">
          {{ title }}
        </b-card-title>
      </div>
      <!-- datepicker -->
      <!-- <div class="d-flex align-items-center">
        <feather-icon icon="CalendarIcon" size="16" />
        <flat-pickr
          v-model="rangePicker"
          :config="{
            mode: 'range',
            altInput: true,
            altFormat: 'j M Y',
            dateFormat: 'Y-m-d',
          }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="YYYY-MM-DD"
        />
      </div> -->
      <!-- datepicker -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <component-horizontal-bar-chart
        :height="400"
        :data="data"
        :options="options"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'

import ComponentHorizontalBarChart from './component/ComponentHorizontalBarChart.vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    flatPickr,
    ComponentHorizontalBarChart,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    title: {
      type: String,
      default: () => ({}),
    },
  },
  data() {
    return {
      rangePicker: ['2022-11-01', '2022-12-10'],
    }
  },
  mounted() {
  },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>
